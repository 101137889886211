import _objectSpread from "/home/runner/work/utopia-centre-platform/utopia-centre-platform/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex'; // import Logo from '@/assets/images/logo.png'

import { baseFileModelUrl } from '@/api/config'; // 默认机构图像

import centre from '@/assets/images/center.jpeg';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: 'Utopia',
      // logo: Logo,
      logo: '',
      centreName: '',
      centerContactPerson: '',
      Avatar: '',
      baseFileModelUrl: baseFileModelUrl
    };
  },
  computed: _objectSpread({}, mapGetters(['user'])),
  created: function created() {
    // console.log(this.$store.state.user.user)
    this.centreName = this.$store.state.user.user.realName || this.title;
    this.centerContactPerson = this.$store.state.user.user.centerContactPerson;
    var avatar = this.$store.state.user.user.avatar;
    this.Avatar = avatar ? baseFileModelUrl + avatar : centre;
  }
};