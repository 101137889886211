import request from '@/utils/request';
import { baseRouterUrl } from '@/api/config'; // 图片请求方法

export function getPicUrl(data) {
  return request({
    url: "".concat(baseRouterUrl, "/file/get/private/file"),
    method: 'post',
    data: data
  });
} // 获取系统配置信息

export function getSyetemConfig(data) {
  return request({
    url: "".concat(baseRouterUrl, "/show/system/config"),
    method: 'post',
    data: data
  });
} // 同意协议

export function agreeAgreement(data) {
  return request({
    url: "".concat(baseRouterUrl, "/user/agree/agreement"),
    method: 'post',
    data: data
  });
} // 获取公共假期

export function getHolidayList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/show/holiday/list"),
    method: 'post',
    data: data
  });
} // 数据统计接口

export function centreHomeStatics(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/home/statics"),
    method: 'post',
    data: data
  });
} // 优惠券数据统计接口

export function centreHomeStaticsCoupon(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/home/statics/coupon"),
    method: 'post',
    data: data
  });
}
export default {
  getPicUrl: getPicUrl,
  getSyetemConfig: getSyetemConfig,
  agreeAgreement: agreeAgreement,
  getHolidayList: getHolidayList,
  centreHomeStatics: centreHomeStatics,
  centreHomeStaticsCoupon: centreHomeStaticsCoupon
};