import "core-js/modules/es6.number.constructor";
import Cookies from 'js-cookie'; // import Config from '@/tools/config'

import Config from '@/settings';
var TokenKey = Config.TokenKey;
var UserKey = Config.UserKey;
var LoadKey = Config.LoadKey; // token

export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token, {
    expires: Config.tokenCookieExpires
  });
}
export function removeToken() {
  console.log('removeToken'); // return Cookies.remove(TokenKey)
} // user

export function getUser() {
  var value_ = Cookies.get(UserKey);
  return value_ ? JSON.parse(value_) : value_;
}
export function setUser(user) {
  return Cookies.set(UserKey, user ? JSON.stringify(user) : user, {
    expires: Config.userCookieExpires
  });
}
export function removeUser() {
  return Cookies.remove(UserKey);
} // global

export function get(key, json) {
  json = json || false;
  var value_ = Cookies.get(key);

  if (json) {
    value_ = value_ ? JSON.parse(value_) : value_;
  }

  return value_;
}
export function set(key, storage, json) {
  json = json || false;

  if (json) {
    storage = storage ? JSON.stringify(storage) : storage;
  }

  return Cookies.set(key, storage);
}
export function remove(key) {
  return Cookies.remove(key);
} // 状态控制

export function pageLoad(value) {
  if (value === 1 || value === 0) {
    return Cookies.set(LoadKey, value);
  }

  var value_ = Cookies.get(LoadKey) || 0;
  return Number(value_);
}