import _objectSpread from "/home/runner/work/utopia-centre-platform/utopia-centre-platform/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import { baseFileModelUrl } from '@/api/config'; // 默认机构图像

import centre from '@/assets/images/center.jpeg';
import { getSyetemConfig as _getSyetemConfig, agreeAgreement, centreHomeStatics, centreHomeStaticsCoupon } from '@/api/common/common';
import '@/assets/font/font.css'; // 引入EventBus事件总线

import { EventBus } from '@/utils/event-bus.js';
export default {
  components: {
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger
  },
  data: function data() {
    return {
      input: '',
      activityPopup: false,
      // 活动弹窗
      couponType: 2,
      Avatar: '',
      // dialogVisible: false,
      baseFileModelUrl: baseFileModelUrl,
      // 用户确认协议弹窗
      dialogIsAgreeVisible: false,
      // 用户协议弹窗控制
      dialogAgreementVisible: false,
      // 平台规则控制开关
      dialogPrivacyPolicyVisible: false,
      // 优惠弹窗
      dialogDiscountVisible: false,
      systemConfig: {},
      termCheck: false,
      centreHomeStatics: {},
      statics: true,
      dialogMessageVisible: false,
      // 消息列表弹窗
      // 消息列表数据
      dialogMessageData: {
        list: [],
        total: 0
      },
      userUnReadNotices: 0 // 新消息数量

    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['sidebar', 'device', 'user', 'baseApi'])), {}, {
    show: {
      get: function get() {
        return this.$store.state.settings.showSettings;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        });
      }
    }
  }),
  created: function created() {
    var _this = this;

    // 接收消息 更新消息数量
    EventBus.$on('update-message', function (data) {
      _this.getMessageNumber();
    });
    var avatar = this.$store.state.user.user.avatar;
    this.Avatar = avatar ? baseFileModelUrl + avatar : centre; // 用户协议

    var agreed = this.$store.state.user.user.agreed; // console.log(this.$store.state.user.user)

    if (!agreed && this.$store.state.user.user.userName) {
      this.dialogIsAgreeVisible = true;
    } else {
      this.dialogIsAgreeVisible = false;
      this.getCentreHomeStatics();
    }

    this.getSyetemConfig();
    this.getMessageNumber();
  },
  methods: {
    // 获取新消息数量
    getMessageNumber: function getMessageNumber() {
      var _this2 = this;

      centreHomeStatics().then(function (res) {
        _this2.userUnReadNotices = res.rtn_data.userUnReadNotices || 0;
      });
    },
    showMessage: function showMessage() {
      this.$router.push('/job/message');
    },
    getSyetemConfig: function getSyetemConfig() {
      var _this3 = this;

      _getSyetemConfig().then(function (res) {
        var data = res.rtn_data;
        _this3.systemConfig = data;
      });
    },
    getCentreHomeStatics: function getCentreHomeStatics() {
      var _this4 = this;

      if (localStorage.getItem('con') === '1') return;
      localStorage.setItem('con', '1');
      centreHomeStaticsCoupon().then(function (res) {
        var data = res.rtn_data;
        _this4.centreHomeStatics = data; // 100抵扣券弹窗 放开即可使用
        // if (this.centreHomeStatics.newCenterCouponFlag) {
        //   this.dialogDiscountVisible = true
        // } else {
        //   this.dialogDiscountVisible = false
        // }
        // data.activeFlag = true // 测试

        _this4.activityPopup = data.activeFlag; // localStorage.removeItem('con')

        localStorage.setItem('con', '');
      });
    },
    // 创建工作
    toCreateJob: function toCreateJob() {
      var that = this; // that.$router.push('/Centre/Create-Job/Standard Booking')

      that.$router.push('/Create-Job/Standard Booking');
      this.dialogDiscountVisible = false;
      this.activityPopup = false;
    },
    handleCloses: function handleCloses(done) {
      var _this5 = this;

      this.$confirm('Are you sure to close ?', {
        confirmButtonClass: 'submitClass',
        cancelButtonClass: 'cancelClass'
      }).then(function (_) {
        done();

        _this5.logout();
      }).catch(function (_) {});
    },
    logout: function logout() {
      this.$store.dispatch('LogOut').then(function () {
        location.reload();
        localStorage.setItem('con', ''); // Cookies.set('staticsFlag', 1)
      });
    },
    // 确认操作
    agreementSubmit: function agreementSubmit(row) {
      var _this6 = this;

      var that = this;

      if (this.setSta) {
        return false;
      }

      console.log(this.termCheck);

      if (!that.termCheck) {
        this.$notify.warning({
          title: 'Please agree to the terms of use',
          type: 'warning'
        });
        return;
      }

      this.setSta = true; // 执行操作

      agreeAgreement().then(function (res) {
        if (res.rtn_code === 0) {
          _this6.dialogIsAgreeVisible = false;
          _this6.$store.state.user.user.agreed = 1;

          _this6.$notify({
            title: res.rtn_msg,
            type: 'success'
          });

          _this6.getCentreHomeStatics();
        } else {
          _this6.$notify.warning({
            title: res.rtn_msg,
            type: 'warning'
          });
        }

        _this6.setSta = false;
      }).catch(function () {
        _this6.setSta = false;
      });
    },
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    open: function open() {
      var _this7 = this;

      this.$confirm(this.$t('common.confirmLogout'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        confirmButtonClass: 'submitClass',
        cancelButtonClass: 'cancelClass',
        type: 'warning'
      }).then(function (e) {
        // console.log(e)
        _this7.logout();
      }).catch(function (e) {// console.log(e)
      });
    },
    search: function search() {}
  }
};