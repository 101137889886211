module.exports = {
  /**
   * @description 网站标题
   */
  // title: 'EL-ADMIN',
  title: 'centre management platform',

  /**
   * @description 是否显示 tagsView
   */
  tagsView: true,

  /**
   * @description 固定头部
   */
  fixedHeader: true,

  /**
   * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
   */
  tokenCookieExpires: 1,
  userCookieExpires: 1,

  /**
   * @description 记住密码状态下的密码在Cookie中存储的天数，默认1天s
   */
  passCookieExpires: 1,

  /**
   * @description 是否只保持一个子菜单的展开
   */
  uniqueOpened: true,

  /**
   * @description token key
   */
  TokenKey: 'utopia-centre-platform-tokenKey',
  UserKey: 'utopia-centre-platform-userKey',
  LoadKey: 'utopia-centre-platform-loadkey',

  /**
   * @description 请求超时时间，毫秒（默认2分钟）
   */
  timeout: 120000,

  /**
   * @description 是否显示logo
   */
  sidebarLogo: true,

  /**
   * 是否显示设置的底部信息
   */
  showFooter: true,

  /**
   * 底部文字，支持html语法
   */
  // footerTxt: 'Copyright © 2021 武汉宏达维文化有限公司',
  footerTxt: '',

  /**
   * 备案号
   */
  // caseNumber: 'HTCD-----WuhanHTCdimensioncultureCo.Ltd'
  caseNumber: ''
};