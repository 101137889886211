import request from '@/utils/request'; // import { baseBlmUrl } from '@/api/config'

import { baseRouterUrl } from '@/api/config';
export function login(data) {
  return request({
    url: "".concat(baseRouterUrl, "/user/password/login"),
    method: 'post',
    data: data
  });
}
export function getInfo() {
  return request({
    url: "".concat(baseRouterUrl, "/user/center/detail"),
    method: 'post'
  });
}
export function logout() {
  return request({
    url: "".concat(baseRouterUrl, "/user/logout"),
    method: 'post'
  });
}