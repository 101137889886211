import "core-js/modules/es6.regexp.to-string";
import axios from 'axios';
import router from '@/router/routers'; // import { Notification, Message } from 'element-ui'

import { Notification } from 'element-ui'; // import store from '../store'

import * as storage from "../tools/storage";
import { getToken, removeToken } from '@/utils/auth';
import { removeUser } from '@/utils/user';
import Config from '@/settings'; // import Cookies from 'js-cookie'
// import Qs from 'qs'
// 创建axios实例

var service = axios.create({
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API,
  // api 的 base_url
  timeout: Config.timeout // 请求超时时间

}); // request拦截器

service.interceptors.request.use(function (config) {
  // 版本更新提示--->开始
  // Message.error({
  // 	message: 'Dear centres: We are upgrading our Web App in these several days. Please use Mobile App to manage your bookings. We apologise for any inconvenience caused. ',
  // 	duration: 3000
  // })
  // // 跳转
  // setTimeout(() => {
  // 	removeToken()
  // 	removeUser()
  // 	location.reload()
  // }, 5000)
  // 版本更新提示--->结束
  if (getToken()) {
    config.headers['token'] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
  } // console.log('service.interceptors.request--->config--->')
  // console.log(JSON.stringify(config))


  config.headers['Content-Type'] = 'application/json'; // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

  config.headers['timestamp'] = Date.parse(new Date());

  if (config.method === 'post') {
    if (config.data) {
      if (config.data.page) {
        config.data.pageNo = config.data.page + 1;
      } else if (config.data.page === 0) {
        config.data.pageNo = 1;
      }

      if (config.data.size) {
        config.data.pageSize = config.data.size;
      } // curd
      // var param = { 'biz_content': JSON.stringify(config.data) }
      // config.data = Qs.stringify(param)

    }
  }

  return config;
}, function (error) {
  Promise.reject(error);
}); // response 拦截器

service.interceptors.response.use(function (response) {
  // 版本更新提示--->开始
  // 跳转
  // Notification.error({
  // 	title: 'Dear centres, We are upgrading our Web App in these several days. Please use Mobile App to manage your bookings. We apologise for any inconvenience caused. ',
  // 	duration: 5000
  // })
  // setTimeout(() => {
  // 	// location.reload()
  // 	removeToken()
  // 	removeUser()
  // 	location.reload()
  // }, 3000)
  // 版本更新提示--->结束
  var result = response.data;
  var code = result.rtn_code;
  var statusCode = response.status; // if (statusCode !== 200 || code !== 0) {

  if (code !== 0) {
    storage.pageLoad(0);
  }

  if (response.data.rtn_code === 1001) {
    removeToken();
    removeUser(); // alert('1001')

    location.reload();
  } else if (response.data.rtn_code === 30030) {
    removeToken();
    removeUser();
    Notification.error({
      title: response.data.rtn_msg
    }); // 跳转

    setTimeout(function () {
      // alert('30030')
      location.reload();
    }, 3000);
  } else {
    var _code = response.status;

    if (_code < 200 || _code > 300) {
      Notification.error({
        title: response.message
      });
    } else if (response.data.rtn_code === 0) {
      return response.data;
    } else {
      Notification.error({
        title: response.data.rtn_msg
      });
    }

    return Promise.reject('error');
  }
}, function (error) {
  if (error.toString().indexOf('timeout') !== -1) {
    Notification.error({
      title: 'Network request timed out',
      duration: 5000
    });
  } else if (error.toString().indexOf('401') !== -1) {
    removeToken();
    removeUser(); // alert('401')

    location.reload();
  } else if (error.toString().indexOf('403') !== -1) {
    router.push({
      path: '/401'
    });
  } else {
    Notification.error({
      title: 'Interface request failed',
      duration: 5000
    });
  }

  return Promise.reject(error);
});
export default service;