exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".activityPopupDialog{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.activityPopupDialog .activityPopup-bg{width:600px}.activityPopupDialog .el-dialog{background-color:transparent;-webkit-box-shadow:none;box-shadow:none;margin-top:0 !important}.activityPopupDialog .el-dialog__header{display:none}.activityPopupDialog .el-dialog__body{padding:0}.activityPopupDialog .activityPopupMain{font-size:0;position:relative}.activityPopupDialog .activityPopupMain .close{position:absolute;top:22px;right:22px;cursor:pointer}.activityPopupDialog .activityPopupMain .skip{position:absolute;top:18px;right:24px;color:#f2643c;font-family:Helvetica;font-size:14px;font-style:normal;font-weight:400;line-height:24px;cursor:pointer}.activityPopupDialog .activityPopupMain .btn{position:absolute;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);bottom:70px;width:208px;height:50px;border-radius:40px;line-height:50px !important;text-align:center;-ms-flex-negative:0;flex-shrink:0;background:#ea7e6f;color:#fff;font-family:\"SF Pro Display\";font-size:20px;font-style:normal;font-weight:700;line-height:normal;cursor:pointer}", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409eff",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "250px",
	"appMainTop": "94px"
};