var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "right-menu" }, [
        _c("div", { staticClass: "search" }, [
          _c(
            "div",
            { staticClass: "notification", on: { click: _vm.showMessage } },
            [
              !_vm.userUnReadNotices
                ? _c("img", {
                    attrs: {
                      src: require("../../assets/images/navbar/notification.svg"),
                      alt: ""
                    }
                  })
                : _c("img", {
                    attrs: {
                      src: require("../../assets/images/navbar/notification2.svg"),
                      alt: ""
                    }
                  }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.userUnReadNotices,
                      expression: "userUnReadNotices"
                    }
                  ],
                  staticClass: "number"
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.userUnReadNotices > 99
                          ? "99+"
                          : _vm.userUnReadNotices
                      ) +
                      "\n        "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "exit", on: { click: _vm.open } }, [
            _c("img", {
              staticClass: "icon1",
              attrs: {
                src: require("../../assets/images/navbar/exit.svg"),
                alt: ""
              }
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "icon2",
              attrs: {
                src: require("../../assets/images/navbar/exitHover.svg"),
                alt: ""
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Agreement",
            visible: _vm.dialogIsAgreeVisible,
            width: "30%",
            "append-to-body": "",
            "before-close": _vm.handleCloses
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogIsAgreeVisible = $event
            }
          }
        },
        [
          _c("el-checkbox", {
            staticStyle: {},
            model: {
              value: _vm.termCheck,
              callback: function($$v) {
                _vm.termCheck = $$v
              },
              expression: "termCheck"
            }
          }),
          _vm._v(" "),
          _c(
            "span",
            [
              _vm._v("\n      I agree to the\n      "),
              _c(
                "el-link",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogAgreementVisible = true
                    }
                  }
                },
                [_vm._v("Terms of Use")]
              ),
              _vm._v("\n      &\n      "),
              _c(
                "el-link",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogPrivacyPolicyVisible = true
                    }
                  }
                },
                [_vm._v("Privacy Policy")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "cancelClass",
                  on: {
                    click: function($event) {
                      return _vm.logout()
                    }
                  }
                },
                [_vm._v("Back")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "submitClass",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.agreementSubmit()
                    }
                  }
                },
                [_vm._v("Next")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Terms of Use",
            visible: _vm.dialogAgreementVisible,
            width: "45%",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogAgreementVisible = $event
            }
          }
        },
        [
          _c("span", {
            staticClass: "text-break content",
            domProps: { innerHTML: _vm._s(_vm.systemConfig.agreement) }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Privacy Policy",
            visible: _vm.dialogPrivacyPolicyVisible,
            width: "45%",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogPrivacyPolicyVisible = $event
            }
          }
        },
        [
          _c("span", {
            staticClass: "text-break content",
            domProps: { innerHTML: _vm._s(_vm.systemConfig.privacyPolicy) }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "discount",
          attrs: {
            title: "",
            visible: _vm.dialogDiscountVisible,
            width: "680px",
            "append-to-body": "",
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogDiscountVisible = $event
            }
          }
        },
        [
          _vm.centreHomeStatics.couponType == 2 &&
          _vm.centreHomeStatics.newCenterCouponFlag
            ? _c("div", { staticClass: "discount_box" }, [
                _c("i", {
                  staticClass: "el-icon-close close",
                  on: {
                    click: function($event) {
                      _vm.dialogDiscountVisible = false
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "discount_title discount_text",
                    staticStyle: { "margin-top": "0px" }
                  },
                  [
                    _c("span", { staticClass: "num" }, [
                      _vm._v(
                        "$" + _vm._s(_vm.centreHomeStatics.parValue || 100)
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "voucher",
                    staticStyle: { "margin-left": "-10px" }
                  },
                  [_vm._v("VOUCHER")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "lp" }, [
                  _c("span", [_vm._v("QUALIFIED")]),
                  _vm._v(" "),
                  _c("span", [_vm._v("BOOKING")]),
                  _vm._v(" "),
                  _c("span", [_vm._v("ONLY")])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "button",
                    staticStyle: { "margin-bottom": "20px" },
                    on: { click: _vm.toCreateJob }
                  },
                  [_vm._v("\n        Book Now\n      ")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex_end" }, [
                  _c("div", { staticClass: "text" }, [
                    _vm._v("\n          T&Cs Apply\n        ")
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("\n          Valid until\n          "),
                    _c("span", { staticClass: "date_text" }, [
                      _vm._v(_vm._s(_vm.centreHomeStatics.validityEndTimeData))
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.centreHomeStatics.couponType == 1 &&
          _vm.centreHomeStatics.newCenterCouponFlag
            ? _c("div", { staticClass: "discount_box discount_box2" }, [
                _c("i", {
                  staticClass: "el-icon-close close",
                  on: {
                    click: function($event) {
                      _vm.dialogDiscountVisible = false
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "discount_title discount_text",
                    staticStyle: { "margin-top": "0px" }
                  },
                  [
                    _c("span", { staticClass: "num" }, [
                      _vm._v(
                        "$" + _vm._s(_vm.centreHomeStatics.parValue || 100)
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "signup" }, [_vm._v("SIGNUP")]),
                _vm._v(" "),
                _c("div", { staticClass: "main" }, [
                  _c("div", { staticClass: "voucher" }, [_vm._v("VOUCHER")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "lp" }, [
                    _c("span", [_vm._v("QUALIFIED")]),
                    _vm._v(" "),
                    _c("span", [_vm._v("BOOKING")]),
                    _vm._v(" "),
                    _c("span", [_vm._v("ONLY")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "button",
                      staticStyle: { "margin-bottom": "18px" },
                      on: { click: _vm.toCreateJob }
                    },
                    [_vm._v("\n          Book Now\n        ")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex_end" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v("\n            T&Cs Apply\n          ")
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("\n            Valid until\n            "),
                      _c("span", { staticClass: "date_text" }, [
                        _vm._v(
                          _vm._s(_vm.centreHomeStatics.validityEndTimeData)
                        )
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "activityPopupDialog",
          attrs: {
            visible: _vm.activityPopup,
            width: "600px",
            "append-to-body": "",
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.activityPopup = $event
            }
          }
        },
        [
          _c("div", { staticClass: "activityPopupMain" }, [
            _c(
              "div",
              {
                staticClass: "skip",
                on: {
                  click: function($event) {
                    _vm.activityPopup = false
                  }
                }
              },
              [_vm._v("Skip")]
            ),
            _vm._v(" "),
            _c("img", {
              staticClass: "activityPopup-bg",
              attrs: {
                src: require("../../assets/images/home_rate_activity.png"),
                alt: ""
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "btn", on: { click: _vm.toCreateJob } }, [
              _vm._v("Check the rate")
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }