import Vue from 'vue';
import Router from 'vue-router';
import Layout from "../layout/index";
Vue.use(Router);
export var constantRouterMap = [{
  meta: {
    title: 'Sign In',
    noCache: true
  },
  path: '/login2',
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/features/redirect'], resolve);
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/index'], resolve);
    },
    name: 'Dashboard',
    // meta: { title: 'Home', icon: 'index', affix: true, noCache: true }
    meta: {
      title: 'Home',
      icon: 'icon-1',
      affix: true,
      noCache: true
    }
  }]
}, {
  path: '/Processing',
  component: Layout,
  // redirect: '/Centre/bookings/Processing',
  redirect: '/bookings/Processing',
  children: []
}, {
  path: '/profile',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/profile/index'], resolve);
    },
    name: 'Personal Center',
    meta: {
      title: 'Personal Center'
    }
  }]
}, {
  path: '/job',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'ur-free-add',
    component: function component(resolve) {
      return require(['@/views/centre/job/ur-free-add'], resolve);
    },
    name: 'Make A Permanent Job Offer',
    meta: {
      title: 'Make A Permanent Job Offer'
    }
  }, {
    path: 'favouritesTeacher',
    component: function component(resolve) {
      return require(['@/views/centre/job/favouritesTeacher'], resolve);
    },
    name: 'Favourites Teacher',
    meta: {
      title: 'Favourites Teacher'
    }
  }, {
    path: 'message',
    component: function component(resolve) {
      return require(['@/views/centre/job/message'], resolve);
    },
    name: 'message',
    meta: {
      title: 'Updates'
    }
  }]
}, {
  // meta: { title: 'Sign In', noCache: true },
  // path: '/signUp',
  path: '/login',
  component: function component(resolve) {
    return require(['@/views/register/index'], resolve);
  },
  hidden: true
}, {
  path: '/mobilePhone',
  name: 'mobilePhone',
  component: function component() {
    return import(
    /* webpackChunkName: "Home" */
    "../views/register/mobilePhone.vue");
  },
  meta: {
    title: 'mobilePhone'
  },
  hidden: true
}, {
  path: '/loginGraphicalCode',
  name: 'loginGraphicalCode',
  component: function component() {
    return import(
    /* webpackChunkName: "Home" */
    "../views/register/loginGraphicalCode.vue");
  },
  meta: {
    title: 'loginGraphicalCode'
  },
  hidden: true
}, {
  path: '/digitCode',
  name: 'digitCode',
  component: function component() {
    return import(
    /* webpackChunkName: "Home" */
    "../views/register/digitCode.vue");
  },
  meta: {
    title: 'digitCode'
  },
  hidden: true
}, {
  path: '/Information',
  name: 'Information',
  component: function component() {
    return import(
    /* webpackChunkName: "Home" */
    "../views/register/Information.vue");
  },
  meta: {
    title: 'Information'
  },
  hidden: true
}, {
  path: '/email',
  name: 'email',
  component: function component() {
    return import(
    /* webpackChunkName: "Home" */
    "../views/register/email.vue");
  },
  meta: {
    title: 'email'
  },
  hidden: true
}, {
  path: '/emailCode',
  name: 'emailCode',
  component: function component() {
    return import(
    /* webpackChunkName: "Home" */
    "../views/register/emailCode.vue");
  },
  meta: {
    title: 'emailCode'
  },
  hidden: true
}, {
  path: '/thanks',
  name: 'thanks',
  component: function component() {
    return import(
    /* webpackChunkName: "Home" */
    "../views/register/thanks.vue");
  },
  meta: {
    title: 'thanks'
  },
  hidden: true
}, {
  path: '/welcome',
  name: 'welcome',
  component: function component() {
    return import(
    /* webpackChunkName: "Home" */
    "../views/register/welcome.vue");
  },
  meta: {
    title: 'welcome'
  },
  hidden: true
}, {
  path: '/forgotPassword1',
  name: 'forgotPassword1',
  component: function component() {
    return import(
    /* webpackChunkName: "Home" */
    "../views/register/forgotPassword1.vue");
  },
  meta: {
    title: 'forgotPassword1'
  },
  hidden: true
}, {
  path: '/forgotPassword2',
  name: 'forgotPassword2',
  component: function component() {
    return import(
    /* webpackChunkName: "Home" */
    "../views/register/forgotPassword2.vue");
  },
  meta: {
    title: 'forgotPassword2'
  },
  hidden: true
}, {
  path: '/forgotPassword3',
  name: 'forgotPassword3',
  component: function component() {
    return import(
    /* webpackChunkName: "Home" */
    "../views/register/forgotPassword3.vue");
  },
  meta: {
    title: 'forgotPassword3'
  },
  hidden: true
} // {
// 	path: '/job',
// 	component: Layout,
// 	hidden: true,
// 	redirect: 'noredirect',
// 	children: [
// 		{
// 			path: 'favouritesTeacher',
// 			component: (resolve) =>
// 				require(['@/views/centre/job/favouritesTeacher'], resolve),
// 			name: 'Favourites Teacher',
// 			meta: { title: 'Favourites Teacher' }
// 		}
// 	]
// }
];
export default new Router({
  mode: 'hash',
  // base: 'admin',
  // mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});