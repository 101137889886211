import request from '@/utils/request';
import { baseRouterUrl } from '@/api/config'; // 获取机构当前创建工作可选择的好友圈列表

export function centerJobBookFavoriteTeacherList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/book/favorite/teacher/list"),
    method: 'post',
    data: data
  });
} // 机构公开匹配中的工作邀请

export function centerJobInvitePublic(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/invite/public"),
    method: 'post',
    data: data
  });
} // 机构延长已被接收的工作

export function centerJobBookExtend(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/book/extend"),
    method: 'post',
    data: data
  });
} // 机构拆分匹配中的工作邀请

export function centerJobInviteSplit(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/invite/split"),
    method: 'post',
    data: data
  });
} // 机构给匹配中的工作邀请额外奖励

export function centerJobAddReward(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/add/reward"),
    method: 'post',
    data: data
  });
} // 系统配置

export function showSystemConfig(data) {
  return request({
    url: "".concat(baseRouterUrl, "/show/system/config"),
    method: 'post',
    data: data
  });
} // 文件地址获取

export function fileGetPrivateFile(data) {
  return request({
    url: "".concat(baseRouterUrl, "/file/get/private/file"),
    method: 'post',
    data: data
  });
} // 文件地址获取-APP用法

export function getTeacherFile(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/allow/teacher/files"),
    method: 'post',
    data: data
  });
} // 机构发布创建工作

export function centerJobCreate(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/create"),
    method: 'post',
    data: data
  });
} // 假期列表

export function showHolidayList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/show/holiday/list"),
    method: 'post',
    data: data
  });
} // 机构取消匹配中的工作邀请

export function centerJobInviteCancel(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/invite/cancel"),
    method: 'post',
    data: data
  });
} // 机构已被接收的工作日程表（book）
// state：2-processing，3-finished，4-leave，5-cancel 6-extend

export function centerJobBookList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/book/list"),
    method: 'post',
    data: data
  });
} // 机构取消已被接收的工作

export function centerJobBookCancel(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/book/cancel"),
    method: 'post',
    data: data
  });
} // 教师资料下载-邮件

export function teacherFilesDownload(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/allow/teacher/files"),
    method: 'post',
    data: data
  });
} // 机构账单下载

export function centreBillsDownload(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/invoice/down"),
    method: 'post',
    data: data
  });
} // 机构发单记录

export function getRecordList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/job/record/list"),
    method: 'post',
    data: data
  });
} // 机构发单详情

export function getRecordDetail(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/job/record/detail"),
    method: 'post',
    data: data
  });
} // 机构发单删除

export function del(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/job/record/delete"),
    method: 'post',
    data: data
  });
} // 机构邀请记录

export function getInviteList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/job/invite/list"),
    method: 'post',
    data: data
  });
} // 机构邀请详情

export function getInviteDetail(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/job/invite/detail"),
    method: 'post',
    data: data
  });
} // 机构发单删除

export function deleteInvite(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/job/invite/delete"),
    method: 'post',
    data: data
  });
} // 工作记录

export function getBookList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/job/book/list"),
    method: 'post',
    data: data
  });
} // 工作记录详情

export function getBookDetail(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/job/book/detail"),
    method: 'post',
    data: data
  });
} // 机构发单删除

export function deleteBook(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/job/book/delete"),
    method: 'post',
    data: data
  });
} // 操作记录

export function getJobOperateRecordList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/job/operate/record/list"),
    method: 'post',
    data: data
  });
} // 导出数据

export function download(data) {
  return request({
    url: "".concat(baseRouterUrl, "/statistical/statistics/jobRecords/export"),
    method: 'post',
    data: data
  });
} // 私有订单教师状态

export function getJobFavouriteTeachers(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/book/favorite/teacher/states"),
    method: 'post',
    data: data
  });
} // 机构确认工作单

export function jobConfirmSheet(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/confirm/time/sheet"),
    method: 'post',
    data: data
  });
} // 机构数据统计接口

export function jobBookCount(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/home/statics"),
    method: 'post',
    data: data
  });
} // 机构创建工作过程记录
// processType 2 | 3

export function centerJobCreateProcess(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/create/process"),
    method: 'post',
    data: data
  });
} // 列表

export function centerJobConfirmTimeSheetList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/confirm/time/sheet/list"),
    method: 'post',
    data: data
  });
}
export function centerJobInviteConfirmList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/invite/confirm/list"),
    method: 'post',
    data: data
  });
} // 机构首页进行中订单

export function centerHomeJobUpcomingList(data) {
  return request({
    url: "".concat(baseRouterUrl, "/center/job/book/upcoming"),
    method: 'post',
    data: data
  });
}
export default {
  getJobFavouriteTeachers: getJobFavouriteTeachers,
  centerJobInvitePublic: centerJobInvitePublic,
  getRecordList: getRecordList,
  getRecordDetail: getRecordDetail,
  del: del,
  getInviteList: getInviteList,
  getInviteDetail: getInviteDetail,
  deleteInvite: deleteInvite,
  getBookList: getBookList,
  getBookDetail: getBookDetail,
  deleteBook: deleteBook,
  getJobOperateRecordList: getJobOperateRecordList,
  download: download,
  jobBookCount: jobBookCount,
  centerJobCreateProcess: centerJobCreateProcess,
  centerHomeJobUpcomingList: centerHomeJobUpcomingList,
  getTeacherFile: getTeacherFile
};