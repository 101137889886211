import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Loader } from '@googlemaps/js-api-loader'; // 引入地图api

import Axios from 'axios';
var loader = new Loader({
  // apiKey: 'AIzaSyB4nhM1NcCHo2ziz3bIuBOXW2QJ4BXAEpA', // 之前的key
  apiKey: 'AIzaSyCLN8bKW0SQuW7BmBmetXR1LzL71tPrPdw',
  // 之前的key
  version: 'weekly',
  // 版本
  libraries: ['places']
});
export default {
  props: {
    lat: {
      type: String,
      default: '-36.842682'
    },
    lng: {
      type: String,
      default: '174.5885967'
    }
  },
  data: function data() {
    return {
      // 地图相关
      keyword: '',
      searchService: null,
      infos: [],
      map: null,
      center: {},
      currentPlace: null,
      markers: [],
      Window: [],
      places: [],
      activeName: 'first',
      options: [],
      brandCategory: [],
      mapdialogVisible: false,
      // 位置数据
      form: {
        latitude: '',
        longitude: '',
        Address: '',
        // 详细地址
        City: '',
        // 城市
        Suburb: '',
        // 区
        postal_code: '',
        // 邮政编码
        country: '' // 国家

      },
      status: true
    };
  },
  mounted: function mounted() {
    this.getLongitudeLatitude(); // 把父级页面传过来的经纬度复制给form

    this.$set(this, 'form', {
      latitude: this.lat,
      longitude: this.lng
    }); // 初始化地图

    this.initMap();
  },
  methods: {
    // 初始化地图
    initMap: function initMap() {
      var _this2 = this;

      var that = this;
      this.center = {
        lat: Number(this.lat),
        lng: Number(this.lng)
      }; // console.log(this.center)

      var center = this.center; // 地图中心店

      var mapOptions = {
        center: center,
        zoom: 14,
        gestureHandling: 'greedy'
      };
      loader.load().then(function (google) {
        // eslint-disable-next-line no-undef
        var map = new google.maps.Map(document.getElementById('getLatLng'), mapOptions); // 创建搜索框

        that.$nextTick(function () {
          var input = document.getElementById('pac-input');
          var searchBox = new google.maps.places.SearchBox(input); // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input); // 定位搜索框在地图内

          map.addListener('bounds_changed', function () {
            searchBox.setBounds(map.getBounds());
          }); // 搜索框选择事件

          searchBox.addListener('places_changed', function () {
            var places = searchBox.getPlaces();

            if (places.length === 0) {
              return;
            }

            _this2.currentPlace = places[0];
          });
        });
        that.map = map;

        if (center.lat === 0 && center.lng === 0) {
          that.getLongitudeLatitude();
        } else {
          that.addMarker(center, map);
        }

        map.addListener('click', function (mapsMouseEvent) {
          var latlng = mapsMouseEvent.latLng.toJSON();
          that.addMarker(latlng, map, true);
        });
        map.addListener('dragend', function (data) {
          var latlng = map.center.toJSON();
          that.addMarker(latlng, map);
        });
      }).catch(function (e) {
        // do something
        console.log(e);
      });
    },
    // 移除点
    removeMarker: function removeMarker(map) {
      this.markers.forEach(function (el) {
        el.setMap(map);
      });
      this.Window.forEach(function (el) {
        el.setMap(map);
      });
      this.markers = [];
      this.Window = [];
    },
    // 地图新增点
    addMarker: function addMarker(location, map, status) {
      var _this3 = this;

      if (!this.status) return;
      this.status = false;
      var that = this; // Add the marker at the clicked location, and add the next-available label
      // from the array of alphabetical characters.
      // 删除地图所有标记点

      this.removeMarker(null); // 地图中心设置为新的标记点

      if (!status) {
        map.setCenter(location);
      }

      this.form.latitude = location.lat;
      this.form.longitude = location.lng; // 请求谷歌详细位置信息

      Axios.get("https://maps.googleapis.com/maps/api/geocode/json?latlng=".concat(location.lat, ",").concat(location.lng, "&language=EN&key=AIzaSyCLN8bKW0SQuW7BmBmetXR1LzL71tPrPdw")).then(function (res) {
        var _data = res.data.results;
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({
          location: _data[0].geometry.location
        }, function (results, status) {
          console.log(results, 'results');
        });
        console.log(_data, 'd'); // console.log(_data);
        // 防止标点多次触发

        setTimeout(function () {
          that.status = true;
        }, 500); // 标点
        // eslint-disable-next-line no-undef

        var marker = new google.maps.Marker({
          position: location,
          // eslint-disable-next-line no-undef
          // animation: google.maps.Animation.DROP,  //标记点动画效果
          map: map
        });

        _this3.markers.push(marker);

        if (_data.length) {
          var item = _data[0];
          that.form.Address = item.formatted_address;
          var index = that.form.Address.lastIndexOf('邮政编码');

          if (index !== -1) {
            that.form.Address = that.form.Address.substring(0, index);
          }

          var arr = [];
          var route = []; // 循环判断类型

          item.address_components.forEach(function (el) {
            el.types.forEach(function (el1) {
              switch (el1) {
                case 'sublocality_level_1':
                  arr.push(el.long_name);
                  break;

                case 'administrative_area_level_1':
                  arr.push(el.long_name);
                  break;

                case 'street_number':
                  route.push(el.long_name);
                  break;

                case 'route':
                  route.push(el.long_name);
                  break;

                case 'country':
                  arr.push(el.long_name);
                  that.form.country = el.long_name;
                  break;

                case 'locality':
                  that.form.City = el.long_name;
                  break;

                case 'postal_code':
                  that.form.postal_code = el.long_name;
                  break;
              }
            });
          });
          that.form.Suburb = String(arr); // 将省市区地点经纬度返回给父级

          that.$emit('selectspot', that.form); // 上方文字介绍
          // if (route.length) {
          //   // eslint-disable-next-line no-undef
          //   const win = new google.maps.InfoWindow({
          //     position: location,
          //     map: map,
          //     content: String(route),
          //     // eslint-disable-next-line no-undef
          //     pixelOffset: new google.maps.Size(0, -50)
          //   })
          //   this.Window.push(win)
          // } else {
          //   // eslint-disable-next-line no-undef
          //   const win = new google.maps.InfoWindow({
          //     position: location,
          //     map: map,
          //     content: `lat:${location.lat} , lng:${location.lng}`,
          //     // eslint-disable-next-line no-undef
          //     pixelOffset: new google.maps.Size(0, -50)
          //   })
          //   this.Window.push(win)
          // }
        }
      }).catch(function () {
        _this3.status = true;
      });
    },
    // 点击搜索按钮
    Search: function Search(e) {
      if (this.currentPlace) {
        var marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.addMarker(marker, this.map);
      }
    },
    // 获取当前位置
    getLongitudeLatitude: function getLongitudeLatitude() {
      var _this = this;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition( // locationSuccess 获取成功的话
        function (position) {
          _this.form.longitude = position.coords.longitude;
          _this.form.latitude = position.coords.latitude;
          _this.center = {
            lng: position.coords.longitude,
            lat: position.coords.latitude
          };

          _this.addMarker(_this.center, _this.map);
        }, // locationError  获取失败的话
        function (error) {
          var errorType = ['您拒绝共享位置信息', '获取不到位置信息', '获取位置信息超时'];
          console.log(errorType[error.code - 1]);
        });
      }
    }
  }
};